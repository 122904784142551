body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f5f3 !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
footer a{
  color: rgb(204, 204, 204) !important;
  text-decoration: none !important;
  line-height: 5px;

}
.text-dim{
  color: rgb(201, 201, 201) !important;
}

.form-control{
  border-radius: 50px !important;
  padding-left: 25px !important;
}
.App {
  text-align: center;
  overflow-x: hidden;
}
.btn-primary{
  background-color: #31303D !important;
  color: #fff !important;
  border: none !important;
  border-radius: 1.7rem !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 400  !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
}
.bg-brandbg{
  background-color:  #f8f5f3 !important;
}
.btn-secondary{
  background-color: #C0D2F6 !important;
  color: #31303D !important;
  border: none !important;
  border-radius: 1.7rem !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 400  !important;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out !important;
}

.hero-container{
  background-color: #ffffff;
  border-radius: 45px;
  margin-top: 60px;
  box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
  
}

.hero-scroll-container{
  overflow-x: hidden!important;
  display: flex!important;
  flex-direction: row!important;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.hero-scroll-container img{
  height: 400px;
  width: 100%;

}

.hero-scroll-inner {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll 20s linear infinite;
  animation-play-state: running;
  animation-delay: 0;
  animation-direction: normal;
  justify-content: center;
}

.number-holder{
  background-color: #31303D;
  height: 50px;
  width: 50px !important;
  border-radius: 25px;
  border-style: none;
  color: #EAE8E8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 30px;
}

.hero-title{
  font-size: 3rem;
  font-weight: 700;
  color: #31303D;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}
.hero-subtitle{
  font-size: 1.1rem;
  font-weight: 400;
  color: #31303D;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}


.work {
  width: 100%; 
  transition: 0.2s;
}
.work .img {
  width: 100%;
  height: 200px;
  position: relative;
  border-radius: 45px;
  overflow: hidden;
  -webkit-box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
  background-size: cover;
  z-index: 0; 
}
.work .img:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  z-index: -1;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.848) 0%, rgba(0, 0, 0, 0.816) 42%, black 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 93, 177, 0)), color-stop(42%, rgba(148, 54, 103, 0)), color-stop(100%, black));
  background: -webkit-linear-gradient(top, rgba(255, 93, 177, 0) 0%, rgba(148, 54, 103, 0) 42%, black 100%);
  background: -o-linear-gradient(top, rgba(255, 93, 177, 0) 0%, rgba(148, 54, 103, 0) 42%, black 100%);
  background: -ms-linear-gradient(top, rgba(226, 23, 128, 0) 0%, rgba(0, 0, 0, 0.858) 42%, black 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(5, 5, 5, 0)), color-stop(42%, rgba(0, 0, 0, 0.839)), to(black));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(5, 5, 5, 0) 42%, black 100%);
  opacity: 1; 
}
.work .text {
  color: #EAE8E8;
  padding-left: 15px;
  padding-right: 5px;
  padding-bottom: 35px;
  padding-top: 0; 
}
.work .text h2 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0; 
}
.work .text h3 a {
  color: #fff; 
}
.work:hover{
  cursor: pointer;
}

.process{
  background-color: #282c34;
  color: #EAE8E8;
  padding: 50px 0;
  box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.highlight-card{
  background-color: #fff;
  color: #282c34;
  padding: 50px 20px 50px 20px;
  border-radius: 45px;
  box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
  transition: 0.2s ease-in-out;
}

.highlight-card img{
  height: 50px !important;
  margin-bottom: 10px !important;
  object-fit: contain;
  
  
}

.highlight-card .card-title{
  font-size: 1.5rem;
  font-weight: 700;
  color: #31303D;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

.highlight-card .card-subtitle{
  font-size: 1.1rem;
  font-weight: 400;
  color: #31303D;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

.highlight-card .card-text{
  font-size: 1.1rem;
  font-weight: 400;
  color: #31303D;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

.highlight-card .card-text a{
  color: #31303D;
}

.highlight-card:hover .card-title, .highlight-card:hover .card-subtitle, .highlight-card:hover .card-text{
  color: #EAE8E8;
}

.highlight-card:hover{
  background-color: #31303D;
  color: #EAE8E8;
  box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
  transition: 0.2s ease-in-out;
  scale: 1.05;
  cursor: pointer;
}


.bg-hr-grey{
  background-color: #282c34 !important;
  color: #EAE8E8 !important;
}

.contact-form{
  background-color: #fff;
  color: #282c34;
  padding: 50px;
  border-radius: 45px;
  box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
}

.white-box-container{
  background-color: #fff;
  color: #282c34;
  padding: 50px;
  border-radius: 45px;
  box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
}
.accordion{
  padding: 20px;
  background-color: #fff;
  border-radius: 45px;
  --bs-accordion-btn-focus-border-color: #86b7fe !important;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25remrgba(13, 110, 253, 0.25) !important;
  --bs-accordion-active-color: #000000 !important;
  --bs-accordion-active-bg: #ffffff !important;
}
.accordion-item{
  border-style: none !important;
}

@keyframes Waves {
  0% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1.05);
  }
}

@keyframes scroll {
  0%{transform:translateX(0%)}
  100%{transform:translateX(-100%)}
  }




